import React, { PropsWithChildren, useCallback, useState } from 'react';
import { Resizable, ResizableProps, ResizeCallbackData, ResizeHandle as ResizeHandleDirection } from 'react-resizable';
import { styled } from '@mui/material';

export type ResizableContainerProps = PropsWithChildren<
    {
        initialHeight: number;
        className?: string;
        dragBodyClassName?: string;
    } & Omit<ResizableProps, 'width' | 'height'> &
        (
            | {
                  initialWidth: number;
                  fullWidth?: false;
              }
            | {
                  initialWidth?: number;
                  fullWidth: true;
              }
        )
>;

const ContainerRoot = styled('div', {
    label: 'ResizableContainerRoot',
})({
    position: 'relative',
    '&::before, &::after': {
        position: 'absolute',
        content: "''",
        height: '0.5px',
        backgroundColor: 'var(--resize-icon-color, #cdcdcd)',
        transform: 'rotate(-45deg)',
        pointerEvents: 'none',
        zIndex: '1',
    },
    '&::before': {
        right: '1px',
        bottom: '6px',
        width: '10px',
    },
    '&::after': {
        right: '-1px',
        bottom: '9px',
        width: '20px',
    },
});

const ContentContainer = styled('div', {
    label: 'ContentContainer',
})({
    width: '100%',
    height: '100%',
});

const ResizeHandle = styled('span', {
    label: 'ResizeHandle',
    shouldForwardProp: propName => propName !== 'direction',
})<{ direction: ResizeHandleDirection }>(({ direction }) => ({
    position: 'absolute',
    ...(['sw', 'se', 'nw', 'ne'].includes(direction) && {
        width: 15,
        height: 15,
    }),
    ...(direction === 'sw' && {
        bottom: 0,
        left: 0,
        cursor: 'sw-resize',
    }),
    ...(direction === 'se' && {
        bottom: '0',
        right: '0',
        cursor: 'se-resize',
    }),
    ...(direction === 'nw' && {
        top: '0',
        left: '0',
        cursor: 'nw-resize',
    }),
    ...(direction === 'ne' && {
        top: '0',
        right: '0',
        cursor: 'ne-resize',
    }),
    ...((direction === 'w' || direction === 'e') && {
        top: '0',
        bottom: '0',
        width: 15,
        cursor: 'ew-resize',
    }),
    ...(direction === 'w' && {
        left: 0,
    }),
    ...(direction === 'e' && {
        right: 0,
    }),
    ...((direction === 's' || direction === 'n') && {
        left: '0',
        right: '0',
        height: 15,
        cursor: 'ns-resize',
    }),
    ...(direction === 's' && {
        bottom: 0,
    }),
    ...(direction === 'n' && {
        top: 0,
    }),
}));

export const ResizableContainer = ({
    children,
    initialWidth,
    initialHeight,
    className,
    fullWidth,
    onResize,
    onResizeStart,
    onResizeStop,
    dragBodyClassName,
    ...other
}: ResizableContainerProps) => {
    const [width, setWidth] = useState(initialWidth ?? 0);
    const [height, setHeight] = useState(initialHeight);

    const handleResize = useCallback(
        (e: React.SyntheticEvent<Element, Event>, data: ResizeCallbackData) => {
            const {
                size: { width, height },
            } = data;
            setWidth(width);
            setHeight(height);
            onResize?.(e, data);
        },
        [onResize],
    );

    const handleResizeStart = useCallback(
        (e: React.SyntheticEvent<Element, Event>, data: ResizeCallbackData) => {
            if (dragBodyClassName) {
                document.body.classList.add(dragBodyClassName);
            }

            onResizeStart?.(e, data);
        },
        [dragBodyClassName, onResizeStart],
    );

    const handleResizeStop = useCallback(
        (e: React.SyntheticEvent<Element, Event>, data: ResizeCallbackData) => {
            if (dragBodyClassName) {
                document.body.classList.remove(dragBodyClassName);
            }

            onResizeStop?.(e, data);
        },
        [dragBodyClassName, onResizeStop],
    );

    const preventDragEvent = useCallback((e: React.DragEvent) => e.preventDefault(), []);

    return (
        <Resizable
            handle={(direction, ref) => <ResizeHandle ref={ref} direction={direction} />}
            resizeHandles={['e', 's', 'se']}
            width={width}
            height={height}
            onResize={handleResize}
            onResizeStart={handleResizeStart}
            onResizeStop={handleResizeStop}
            {...other}
        >
            <ContainerRoot
                onDrag={preventDragEvent}
                className={className}
                style={{ width: fullWidth ? '100%' : width, height }}
            >
                <ContentContainer>{children}</ContentContainer>
            </ContainerRoot>
        </Resizable>
    );
};
