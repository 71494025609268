import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

enum VinStatus {
    NEW = 'NEW',
    LOAD = 'LOAD',
    TRQ = 'TRQ',
    DLV = 'DLV',
    SCH = 'SCH',
    UNSCH = 'UNSCH',
    CAN = 'CAN',
}

const VinStatusI18n = createDefaultI18n<EnumI18n<typeof VinStatus>>('enums.VinStatus', {
    NEW: 'New',
    LOAD: 'Loaded',
    TRQ: 'Requested',
    DLV: 'Delivered',
    SCH: 'Assigned to truck',
    UNSCH: 'Not assigned to truck',
    CAN: 'Canceled',
});

export const VinStatusBundle: EnumBundle<typeof VinStatus> = {
    value: VinStatus,
    defaultI18n: VinStatusI18n,
    icons: true,
};
