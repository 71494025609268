import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

enum OEMMessageStatus {
    READY = 'READY',
    SENDING = 'SENDING',
    SENT = 'SENT',
    CAN = 'CAN',
    ERR = 'ERR',
}

const OEMMessageStatusI18n = createDefaultI18n<EnumI18n<typeof OEMMessageStatus>>('enums.OEMMessageStatus', {
    READY: 'Ready',
    SENDING: 'Sending',
    SENT: 'Sent',
    CAN: 'Canceled',
    ERR: 'Error',
});

export const OEMMessageStatusBundle: EnumBundle<typeof OEMMessageStatus> = {
    value: OEMMessageStatus,
    defaultI18n: OEMMessageStatusI18n,
    icons: true,
};
