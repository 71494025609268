import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n, EnumIcons } from './EnumTypes';

export enum TruckStatus {
    FREE = 'FREE',
    ORD = 'ORD',
    LOAD = 'LOAD',
    NAV = 'NAV',
    OK = 'OK',
    ACD = 'ACD',
    TCH = 'TCH',
    OTH = 'OTH',
}

const enumI18n = createDefaultI18n<EnumI18n<typeof TruckStatus>>('enums.TruckStatus', {
    FREE: 'Free',
    ORD: 'Ordered',
    LOAD: 'Loaded',
    NAV: 'Not available',
    OK: 'OK',
    ACD: 'Accident',
    TCH: 'Technical problem',
    OTH: 'Blocked',
});

const truckStatusIcons: EnumIcons<typeof TruckStatus> = {
    ACD: { color: '', path: 'StatusIcons/ACD_EXCLM.svg' },
};

export const TruckStatusBundle: EnumBundle<typeof TruckStatus> = {
    value: TruckStatus,
    defaultI18n: enumI18n,
    icons: truckStatusIcons,
};
