import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum TransportationStatus {
    ORD = 'ORD',
    DLV = 'DLV',
    LOAD = 'LOAD',
    LATE = 'LATE',
}

const transportationStatusI18n = createDefaultI18n<EnumI18n<typeof TransportationStatus>>(
    'enums.TransportationStatus',
    {
        ORD: 'Ordered',
        DLV: 'Delivered',
        LOAD: 'Loaded',
        LATE: 'Late',
    },
);

export const TransportationStatusBundle: EnumBundle<typeof TransportationStatus> = {
    value: TransportationStatus,
    defaultI18n: transportationStatusI18n,
    icons: true,
};
