import { Find_classifiers } from '@alcs/beans';
import { createDefaultI18n } from '@alcs/i18n';
import { Als_utl, emptyServiceCallback } from '@alcs/services';

import { ClassifierDomains } from './ClassifierDomains';
import { EnumBundle } from './EnumTypes';

export const EmptyEnumBundle: EnumBundle<Record<string, string>> = {
    defaultI18n: createDefaultI18n('', {}),
    value: {},
};

export const isOneOf = <E>(value: string | null, ...options: E[]): boolean =>
    options.findIndex(option => option === value) !== -1;

/**
 * Function, which converts classifiers to enum bundle (value_code -> code, value_description -> defaultI18n, name -> 'none')
 * @param classifiers Classifiers which will be coverted into enum
 */
export const convertClassifiersToEnum = <T extends Record<string, string>>(
    classifiers: Find_classifiers[],
): EnumBundle<T> =>
    classifiers.reduce<EnumBundle<Record<string, string>>>(
        (acc, currentValue) => {
            acc.value[currentValue.value_code] = currentValue.value_code;
            acc.defaultI18n.values[currentValue.value_code] = currentValue.value_description;
            return acc;
        },
        {
            value: {},
            defaultI18n: createDefaultI18n('', {}),
        },
    ) as EnumBundle<T>;

/**
 * Function, which downloads classifiers and coverts them into EnumBundle via convertClassifiersToEnum @see convertClassifiersToEnum
 * @param domainName name of domain, from where will classifiers be downloaded
 */
export const loadEnumFromDomain = async <T extends Record<string, string>>(
    domainName: ClassifierDomains,
): Promise<EnumBundle<T>> => {
    const service = new Als_utl(emptyServiceCallback);
    const classifiers = await service.find_classifiers(domainName);
    return convertClassifiersToEnum<T>(classifiers);
};

export const filterEnumBundle = (
    enumBundle: EnumBundle<Record<string, string>>,
    filterFunction: (value: string) => boolean,
): EnumBundle<Record<string, string>> =>
    Object.entries(enumBundle.value).reduce(
        (filteredBundle, [enumKey, value]) => {
            if (filterFunction(value)) {
                filteredBundle.value[enumKey] = value;
            }
            return filteredBundle;
        },
        { ...enumBundle, value: {} } as EnumBundle<Record<string, string>>,
    );

export const removeDuplicates = (
    enumBundle: EnumBundle<Record<string, string>>,
): EnumBundle<Record<string, string>> => {
    let keysArray = Object.keys(enumBundle.value);

    keysArray = keysArray.sort(
        (a, b) => enumBundle.defaultI18n.values[a].length - enumBundle.defaultI18n.values[b].length,
    );

    const reserved: string[] = [];

    keysArray = keysArray.filter(key => {
        const reservedKey = key.substring(key.lastIndexOf('.') + 1);
        const include = !reserved.includes(reservedKey);
        if (include) {
            reserved.push(reservedKey);
        }
        return include;
    });

    return {
        ...enumBundle,
        value: keysArray.reduce<Record<string, string>>((acc, key) => {
            acc[key] = enumBundle.value[key];
            return acc;
        }, {}),
    };
};
