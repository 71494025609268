import { createDefaultI18n } from '@alcs/i18n';

import { EnumBundle, EnumI18n } from './EnumTypes';

export enum LoadStatus {
    NEW = 'NEW',
    NAV = 'NAV',
    TRQ = 'TRQ',
    ORD = 'ORD',
    UNSCH = 'UNSCH',
    SCH = 'SCH',
    LOAD = 'LOAD',
    DLV = 'DLV',
}

const defaultI18n = createDefaultI18n<EnumI18n<typeof LoadStatus>>('enums.LoadStatus', {
    NEW: 'New',
    NAV: 'Not available',
    TRQ: 'Requested',
    ORD: 'Ordered',
    UNSCH: 'Not assigned to truck',
    SCH: 'Assigned to truck',
    LOAD: 'Loaded',
    DLV: 'Delivered',
});

export const LoadStatusBundle: EnumBundle<typeof LoadStatus> = {
    value: LoadStatus,
    defaultI18n,
    icons: true,
};
