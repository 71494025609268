import React, { useCallback, useEffect, useState } from 'react';
import { Icon, IconProps } from '@mui/material';

import { ShakeContainer, ShakeContainerProps } from '../containers/ShakeContainer';
import { ErrorTooltip } from '../MaterialTheme';

interface Props extends Omit<ShakeContainerProps, 'children' | 'shake'> {
    error: undefined | string | Record<string, string>;
    fontSize?: IconProps['fontSize'];
    children?: React.ReactElement;
    disableTooltip?: boolean;
}

const getStyledError = (error: undefined | string | Record<string, string>) =>
    typeof error !== 'object' || error === null ? (
        error ?? ''
    ) : (
        <React.Fragment>
            {Object.keys(error).map((label, index) => (
                <div key={index}>
                    <span>{label} - </span>
                    <span>{error[label]}</span>
                </div>
            ))}
        </React.Fragment>
    );

export const ErrorIcon = ({ error, children, fontSize = 'small', disableTooltip, ...other }: Props) => {
    const [shake, setShake] = useState(Boolean(error));
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (error && shake) {
            setShake(false);
            setTimeout(() => setShake(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const openTooltip = useCallback(() => setOpen(true), []);

    const closeTooltip = useCallback(() => setOpen(false), []);

    return (
        <ShakeContainer shake={shake} style={{ height: 17.14 }} {...other}>
            <ErrorTooltip
                onOpen={openTooltip}
                onClose={closeTooltip}
                open={!disableTooltip && open && Boolean(error)}
                arrow
                title={getStyledError(error)}
            >
                {children ?? (
                    <Icon color="error" fontSize={fontSize}>
                        error
                    </Icon>
                )}
            </ErrorTooltip>
        </ShakeContainer>
    );
};
