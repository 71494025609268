export enum DocType {
    CON = 'CON',
    UCON = 'UCON',
    INV = 'INV',
    OFR = 'OFR',
    ORD = 'ORD',
    ORDI = 'ORDI',
    TND = 'TND',
    TOFR = 'TOFR',
    TRQ = 'TRQ',
    RNT = 'RNT',
    NTL = 'NTL',
    CVH = 'CVH',
    CMP = 'CMP',
    UAPL = 'UAPL',
    APL = 'APL',
    DPST = 'DPST',
    APL_REG = 'APL_REG',
    APL_VAT = 'APL_VAT',
    VSH = 'VSH',
    AUL = 'AUL',
    APL_INS = 'APL_INS',
    PSUB = 'PSUB',
    PPAY = 'PPAY',
    CTR = 'CTR',
    CM = 'CM',
    VTRQ = 'VTRQ',
    BPMN = 'BPMN',
    INC = 'INC',
    RDAY = 'RDAY',
    RCTR = 'RCTR',
    CHAT = 'CHAT',
    OEM = 'OEM',
    BAC = 'BAC',
    COM = 'COM',
    CLAIM = 'CLAIM',
    USR = 'USR',
    ERQ = 'ERQ',
    LSC = 'LSC',
}
