import moment from 'moment';

export const MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000;

type DateTimeFieldCode =
    | 'era'
    | 'year'
    | 'quarter'
    | 'month'
    | 'weekOfYear'
    | 'weekday'
    | 'day'
    | 'dayPeriod'
    | 'hour'
    | 'minute'
    | 'second'
    | 'timeZoneName';

const EN_CUSTOM_LOCALE = {
    d: '1 day',
    M: '1 month',
    y: '1 year',
};

const LT_CUSTOM_LOCALE = {
    d: '1 diena',
    M: '1 mėnuo',
    y: '1 metai',
};

export function getWeekNumber(date: Date) {
    return moment(date).isoWeek();
}

export function getWeeksInMonth(date: Date) {
    date = new Date(date.getFullYear(), date.getMonth());
    return Math.ceil((getDaysCount(date) + getWeekdayOffset(date, true)) / 7);
}

export const getUniqueWeeksCount = (dateA: Date, dateB: Date) =>
    Math.abs(moment(dateA).startOf('isoWeek').diff(moment(dateB).startOf('isoWeek'), 'week')) + 1;

export function getWeekdayOffset(date: Date, fromMonday?: boolean): number {
    return (date.getDay() + (fromMonday ? 6 : 7)) % 7;
}

export function getMonthFirstDay(date: Date) {
    const day = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    return (day === 0 ? 7 : day) - 1;
}

export function trimTimeFromDate(date: Date) {
    return moment(date).hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
}

export function getDaysCount(date: Date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
}

export function formatStampToDate(date: number): string {
    return moment(new Date(date)).format('DD.MM.YYYY');
}

export function formatStampToDateDayOfWeek(date: number, countryCode?: string) {
    countryCode && updateLocale(countryCode);
    return moment(new Date(date)).format('dddd, DD.MM.YYYY');
}

export function formatStampToSecs(date: number): string {
    return moment(new Date(date)).format('DD.MM.YYYY HH:mm:ss');
}

export function formatStampToDateTime(date: number): string {
    return moment(new Date(date)).format('DD.MM.YYYY HH:mm');
}

export function formatStampToTime(date: number): string {
    return moment(new Date(date)).format('HH:mm');
}

export function formatDate(date: Date): string {
    return moment(date).format('DD.MM.YYYY');
}

export function formatDateTime(date: Date): string {
    return moment(date).format('DD.MM.YYYY HH:mm');
}

export function formatDateTimeUTC(date: Date): string {
    return moment(date).utc().format('DD.MM.YYYY HH:mm');
}

export function formatDateWithLocale(date: Date, locale: string, format?: string) {
    updateLocale(locale);
    return format ? moment(date).format(format) : formatDate(date);
}

export function stringDateToMillis(date: string): number {
    return moment(date, 'DD.MM.YYYY HH:mm').toDate().getTime();
}

export function isValidDate(date: string): boolean {
    return moment(date, 'DD.MM.YYYY').isValid();
}

export function isSame(date1: Date, date2: Date, granularity?: moment.unitOfTime.StartOf): boolean {
    return moment(date1).isSame(date2, granularity);
}

export function isBefore(date1: Date, date2: Date, granularity?: moment.unitOfTime.StartOf): boolean {
    return moment(date1).isBefore(date2, granularity);
}

export function isAfter(date1: Date, date2: Date, granularity?: moment.unitOfTime.StartOf): boolean {
    return moment(date1).isAfter(date2, granularity);
}

export function dateToDateObject(date: Date) {
    return moment(date).toObject();
}

export function dateObjectToDate(dateObj: moment.MomentObjectOutput) {
    return moment(dateObj).toDate();
}

export function isValidDateTime(date: string): boolean {
    return moment(date, 'DD.MM.YY HH:mm').isValid();
}

export function dateFromString(date: string): Date {
    return moment(date, 'DD.MM.YYYY').toDate();
}

export function dateTimeFromString(date: string): Date {
    return moment(date, 'DD.MM.YYYY HH:mm').toDate();
}

export function stringToMillis(date: string): number {
    return new Date(date).getTime();
}

export const beginningOfDay = (date: Date): Date =>
    new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

export const endOfDay = (date: Date): Date => {
    const roundedDate = beginningOfDay(date);
    roundedDate.setDate(roundedDate.getDate() + 1);
    roundedDate.setTime(roundedDate.getTime() - 1);
    return roundedDate;
};

export const addDays = (date: Date, count: number): Date => moment(date).add(count, 'days').toDate();

export const subtractDays = (date: Date, count: number): Date => moment(date).subtract(count, 'days').toDate();

export const getStartOfISOWeek = (date: Date): Date => moment(date).startOf('isoWeek').toDate();

export const getNextMonday = (from: Date): Date => {
    return moment(from).day(8).toDate();
};

export const getDatesDifference = (from: Date, to: Date): number => {
    const secondDate = moment(from);
    return secondDate.diff(moment(to)) / MILLISECONDS_IN_DAY;
};

export const minDate = (date1: Date, date2: Date) => {
    if (date1.getTime() < date2.getTime()) {
        return date1;
    }

    return date2;
};

export const maxDate = (date1: Date, date2: Date) => {
    if (date1.getTime() > date2.getTime()) {
        return date1;
    }

    return date2;
};

export const getRemainingTime = (endDate: Date, countryCode: string): string => {
    const startDate = new Date();
    if (startDate > endDate) {
        return '';
    }
    updateLocale(countryCode);
    const remainder = moment(endDate).from(startDate, true);
    return remainder;
};

export const getDateTimeUnits = (
    code: DateTimeFieldCode,
    { language = 'en', style }: { language?: string; style?: Intl.DisplayNamesOptions['style'] },
) => new Intl.DisplayNames(language, { type: 'dateTimeField', style }).of(code);

export const getBegginingOfPrevYear = () => {
    return new Date(new Date().getFullYear() - 1, 0, 1);
};

const updateLocale = (countryCode: string) => {
    let localeString = '';
    switch (countryCode) {
        case 'EN':
            moment.updateLocale('en-gb', {
                relativeTime: EN_CUSTOM_LOCALE,
            });
            localeString = 'en-gb';
            break;
        case 'CZ':
            localeString = 'cs';
            break;
        case 'DE':
            localeString = 'de';
            break;
        case 'ES':
            localeString = 'es';
            break;
        case 'FR':
            localeString = 'fr';
            break;
        case 'IT':
            localeString = 'it';
            break;
        case 'LT':
            moment.updateLocale('lt', {
                relativeTime: LT_CUSTOM_LOCALE,
            });
            localeString = 'lt';
            break;
        case 'LV':
            localeString = 'lv';
            break;
        case 'PL':
            localeString = 'pl';
            break;
        case 'RU':
            localeString = 'ru';
            break;
        case 'CN':
            localeString = 'zh-cn';
            break;
        case 'HR':
            localeString = 'hr';
            break;
        case 'DK':
            localeString = 'da';
            break;
        case 'NL':
            localeString = 'nl';
            break;
        case 'EE':
            localeString = 'et';
            break;
        case 'FI':
            localeString = 'fi';
            break;
        case 'HU':
            localeString = 'hu';
            break;
        case 'NO':
            localeString = 'nn';
            break;
        case 'PT':
            localeString = 'pt';
            break;
        case 'RO':
            localeString = 'ro';
            break;
        case 'SK':
            localeString = 'sk';
            break;
        case 'SE':
            localeString = 'sv';
            break;
        default:
            localeString = 'en-gb';
    }
    moment.locale(localeString);
};
